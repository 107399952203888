.mycard{
    padding:8px 14px;
   
}
.turn-blue{
	background-color: #6200ea !important;
	color: white !important;
	
  }
.collection-item:hover{
	cursor: pointer;
}

@media (max-width: 700px){
.myresponsive-list{
max-height: 428px;
overflow-y: scroll;	
}

   .item2{
	  flex:1
   }
   


}

.mychip{
	position:fixed;
	bottom:24px;
	right:20px;
	z-index: 2;
	background-color:#8080809c;
	padding:10px;
	border-radius:20px;
}

@media (min-width: 700px) {
.item1{
 position: relative;
 flex:3;
 padding-bottom: 0;
 height: initial;
}
.item2{
   flex:1
}
.course-card{
	width: 30%;
	margin: 10px;
}
.myresponsive-list{
	padding: 0;
	/* padding-bottom: 168.25%; */
	height: 80vh;
	overflow-y: scroll;
	margin: 0;
}
.video-pad{
	padding: 20px 20px;
}

}

  
/* .videoWrapper {
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 25px;
    height: 0;
  
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

@media (min-width: 700px){
    .rootvideo {
        margin:0px 37px
    }
} */