.mycon{
  height: auto;
  margin-top: 8%

}
body{
  font-family:  'Montserrat', sans-serif;
  height: 100%;
  background: #ebebeb;
  /* background: url('./images/bg.png') no-repeat center center fixed;
  background-size: cover; */

}
.mera{
  background: #6200ee;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #6200ee, #ff04ab);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom , #6200ee, #ff04ab); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  background-size: cover;
}

svg {
  bottom: 0;
  width: 100%;
  height: 100px;
}
.mysvg{

 box-shadow: inset 2px -26px 18px -11px rgb(106, 91, 230);
 display: inherit;
 /* background: #ebebeb; */
}

.bigbackground{
  padding-bottom:5%;
  color:white;
  text-align:center;
  font-size: 30px;
  padding-top: 83px;
  height: 75vh;
  font-weight: bold;
     
}

.fa{
   font-size:  calc(1em + 5vw);
  color: rgb(255, 255, 255);
  padding: 20px;
  
}
.herotext{
  background: #6200ee;
  width: fit-content;
  /* margin: 17px auto; */
  font-size: 24px;
  padding: 5px;

}


@keyframes amimateball {
  0%{
       transform: translateY(0px);
       opacity: 1;

  }

  100%{
       opacity: 0;
       transform: translateY(-180px);
  
  }
}


@media (max-width: 600px) {

  .herotext{
    font-size: 19px;
  }
  .bigbackground{
    padding-top: 11px;
     margin-bottom: 17%;
  }
  
  .flex-s {
    display: flex;
    flex-direction: column; /* Stack on top */
  }
  .box-a {
    order: 2; /* Go down, bring Box B up */
    margin-top:24px;
    margin-bottom: 60px;
  }
  .con1{
    width: 100%;
  }
}


@media (min-width: 700px) {
  
  .mylogo{
    padding: 20px
  }
  
  
  .circle0 {
    position: absolute;
    top: 90%;
    left: 51%;
    z-index: -1;
    border: 1px solid #6200ee;
    background: radial-gradient(rgba(0,0,0,0),#6300ee1f);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    animation: amimateball 3s infinite;
    animation-timing-function:linear
  }

  .circle2 {
    position: absolute;
    top: 90%;
    left: 11%;
    z-index: -1;
    border: 1px solid #6200ee;
    background: radial-gradient(rgba(0,0,0,0),#6300ee63);

    width: 80px;
    height: 80px;
    border-radius: 50%;
    animation: amimateball 4s infinite;
    animation-timing-function:linear;
  }
  .circle3 {
    position: absolute;
    top: 90%;
    right: 15%;
    z-index: -1;
    border: 1px solid #6200ee;
    background: radial-gradient(rgba(0,0,0,0),#6300ee63);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    animation: amimateball 5s infinite;
    animation-timing-function:linear;
    animation-delay: 1s;
  }
  
}